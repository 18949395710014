






import { IError, ITmallCredentials } from '@/types/interfaces';
import { Component, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class';

@Component({})
export default class TmallLoginPage extends Vue {

  @Action('login', { namespace: 'tmall' }) login: any;

  mounted() {
    this.login().then((result: any) => {
      window.location = result.url
    }).catch((err: IError) => {
      this.$notify({ type: 'error', text: err.message })
    })
      
  }
}
